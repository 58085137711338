require(
  [
    "jquery",
    "slick",
    "mage/multiselect"
  ],
  function ($) {
    // ---------- APP START
    window.App = {

      config: {
        scrollOffset: 110
      },

      init: function () {
        $(".ldv-toggle-nav-js").on("click", function () {
          $("html").toggleClass("ldv-nav-open");
        });

        $(".category-item.level0 > a").on("click", function (e) {
          if ($(window).width() < 1024 && ($(this).outerWidth() - e.clientX) < 120) {
            e.preventDefault();

            var $parent = $(this).parent();
            var $grand_parent = $(this)
              .parents(".ldv-nav")
              .first();

            if ($parent.hasClass("m--opened")) {
              $parent.removeClass("m--opened");
              $parent.find(".level0.submenu").css("height", 0);
              $grand_parent.removeClass(function (index, className) {
                return (
                  className.match(/(^|\s)context-\S+/g) || []
                ).join(" ");
              });
            } else {
              $grand_parent
                .find(".category-item.level0")
                .removeClass("m--opened");
              $grand_parent.find(".level0.submenu").css("height", 0);
              $grand_parent.removeClass(function (index, className) {
                return (
                  className.match(/(^|\s)context-\S+/g) || []
                ).join(" ");
              });

              $parent.addClass("m--opened");

              var height = 0;
              $parent
                .find(".level0.submenu > .column")
                .each(function () {
                  height += $(this).outerHeight(true);
                });
              $parent.find(".level0.submenu").css("height", height);

              var color = $parent.data("color");
              if (color != undefined) {
                $grand_parent.addClass("context-" + color);
              }
            }
          }
        });

        $(".category-item.level0").on("mouseenter", function (e) {
          if ($(window).width() >= 1024) {
            $(".page-wrapper").addClass("m--overlayed");
            var $submenu = $(this).find(".level0.submenu").first();
            var height = 0;
            $submenu.find(".column > ul").each(function () {
              height = Math.max(height, $(this).outerHeight());
            });
            $submenu.each(function () {
              $(this).css("max-height", height);
            });
          }
        });

        $(".category-item.level0").on("mouseleave", function (e) {
          if ($(window).width() >= 1024) {
            $(".page-wrapper").removeClass("m--overlayed");
            var $submenu = $(this).find(".level0.submenu").first();
            $submenu.each(function () {
              $(this).css("max-height", 0);
            });
          }
        });

        $('a.js-scroll').on('click.scroll', function (e) {
          e.preventDefault();
          var anchor = $(this).attr('href');
          var $anchor = $(anchor);
          if ($anchor.length) {
            history.replaceState(null, document.title, anchor);
            App.scroll($anchor);
          }
        });

        App.sticky_header();
        App.showhide_search();
        App.our_range();
        App.our_selection_slider();
        App.products_slider();
        App.products_slider_with_bg();
        App.products_preview_slider();
        App.product_bottom_bar();
        App.back_to_top();
        App.copy_to_clipboard.initCopyText();
        App.block_video();

        App.scroll('.js-scroll-on-load');
      },

      scroll: function (id, offset) {
        if (id != '#' && $(id).length) {
          offset = offset === undefined ? App.config.scrollOffset : offset;
          $('html, body').animate({ scrollTop: $(id).offset().top - offset }, 'slow');
        }
      },

      sticky_header: function () {
        $(window).on('scroll', function () {
          window.requestAnimationFrame(function () {
            var $header = $('.ldv-header');
            var scroll = $(window).scrollTop();
            $header.toggleClass('m--shrinked', (scroll >= App.config.scrollOffset));
          });
        }).trigger('scroll');
      },

      showhide_search: function () {
        var scrollDir = 1;
        var lastScroll = 0;
        $(window).on('scroll', function () {
          window.requestAnimationFrame(function () {
            var $search = $('.ldv-search');
            var scroll = $(window).scrollTop();
            scrollDir = scroll - lastScroll;
            var show = (scroll < App.config.scrollOffset) || scrollDir < 0;
            $search.toggleClass('m--hidden', !show);
            lastScroll = scroll;
          });
        }).trigger('scroll');
      },

      our_range: function () {
        $(".or__element.m--carto").on("mouseenter", function () {
          $(this).parents(".our-ranges").first().addClass("hover-carto");
        });
        $(".or__element.m--guides").on("mouseenter", function () {
          $(this).parents(".our-ranges").first().addClass("hover-guides");
        });
        $(".or__element.m--books").on("mouseenter", function () {
          $(this).parents(".our-ranges").first().addClass("hover-books");
        });

        $(".or__element").on("mouseleave", function () {
          $(this)
            .parents(".our-ranges")
            .first()
            .removeClass("hover-guides")
            .removeClass("hover-books")
            .removeClass("hover-carto");
        });
      },

      our_selection_slider: function () {
        $(".our-selection-slider").each(function () {
          var $slider = $(this).find(".oss__slider");

          $slider.slick({
            arrows: true,
            prevArrow: $slider.parent().find(".arrow-prev"),
            nextArrow: $slider.parent().find(".arrow-next"),
            dots: true,
            appendDots: $slider.parent().find(".dots"),
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            variableWidth: true,
            responsive: [
              {
                breakpoint: 1080,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 580,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          });
        });
      },

      products_slider: function () {
        $(".products-slider").each(function () {
          var $slider = $(this).find(".ps__slider");

          $slider.slick({
            arrows: true,
            prevArrow: $slider.parent().find(".arrow-prev"),
            nextArrow: $slider.parent().find(".arrow-next"),
            dots: true,
            appendDots: $slider.parent().find(".dots"),
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            variableWidth: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          });
        });
      },

      products_slider_with_bg: function () {
        $(".products-slider-with-bg").each(function () {
          var $slider = $(this).find(".pswb__slider");

          $slider.slick({
            arrows: true,
            prevArrow: $slider.parent().find(".arrow-prev"),
            nextArrow: $slider.parent().find(".arrow-next"),
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          });
        });
      },

      products_preview_slider: function () {
        $(".product-preview").each(function () {
          var $slider = $(this).find(".product-preview__slider");

          $slider.slick({
            arrows: true,
            prevArrow: $slider.parent().find(".arrow-prev"),
            nextArrow: $slider.parent().find(".arrow-next"),
            dots: true,
            appendDots: $slider.parent().find(".dots"),
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 2,
                },
              },
            ],
          });
        });
      },

      product_bottom_bar: function () {
        if ($('.ldv-bottom-bar').length && $('#product_addtocart_form').length) {
          var $bottomBar = $('.ldv-bottom-bar');
          var $form = $('#product_addtocart_form');
          $(window).on('scroll', function () {
            window.requestAnimationFrame(function () {
              var scroll = $(window).scrollTop();
              var formBottom = ($form.offset().top + $form.outerHeight() - 80);
              $bottomBar.toggleClass('m--visible', (scroll >= formBottom));
            });
          }).trigger('scroll');
        }
      },

      back_to_top: function () {
        $(".back-to-top-js").on("click", function () {
          $("html,body").animate({ scrollTop: 0 }, 700);
        });
        $(".mini-back-to-top-js").on("click", function () {
          $("html,body").animate({ scrollTop: 0 }, 700);
        });
        if ($(".back-to-top-js").length) {
          $(window).on("scroll", function () {
            window.requestAnimationFrame(function () {
              if (
                $(window).scrollTop() + window.innerHeight >
                $(".back-to-top-js").offset().top - 50
              ) {
                $(".mini-back-to-top-js").css("opacity", 0);
              } else {
                $(".mini-back-to-top-js").css("opacity", 1);
              }
            });
          });
        }
      },

      copy_to_clipboard: {
        fallbackCopyTextToClipboard: function (text) {
          var textArea = document.createElement("textarea");
          textArea.value = text;

          // Avoid scrolling to bottom
          textArea.style.top = "0";
          textArea.style.left = "0";
          textArea.style.position = "fixed";

          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          var copy_with_success = false;
          try {
            var successful = document.execCommand("copy");
            var msg = successful ? "successful" : "unsuccessful";
            copy_with_success = true;
          } catch (err) { }

          document.body.removeChild(textArea);
          return copy_with_success;
        },
        copyTextToClipboard: function (text) {
          var copy_with_success = false;
          if (!navigator.clipboard) {
            copy_with_success =
              App.copy_to_clipboard.fallbackCopyTextToClipboard(text);
            return copy_with_success;
          }
          navigator.clipboard.writeText(text).then(
            function () {
              copy_with_success = true;
            },
            function (err) { }
          );
          return copy_with_success;
        },

        initCopyText: function () {
          $(".copy-link-js").on("click", function () {
            var copy_with_success =
              App.copy_to_clipboard.copyTextToClipboard(
                $(this).data("url")
              );
            if (copy_with_success) {
              var msg = $(this).data("msg");
              var $msg = $(
                '<div class="sharebox-message">' + msg + "</div>"
              ).appendTo(".sharebox-message-container");
              setTimeout(function () {
                $msg.remove();
              }, 5000);
            }
          });
        },
      },

      block_video: function () {
        $('.media-video__btn-js').click(function () {
          var $this = $(this);
          $this.stop(1, 1).fadeOut(200, function () {
            if ($this.data("video-type") == "vimeo") {
              $this
                .parent()
                .html(
                  '<iframe src="https://player.vimeo.com/video/' +
                  $(this).data("video-embed") +
                  '?autoplay=1&loop=1&autopause=0&dnt=1" frameborder="0" allow="autoplay" ; fullscreen" allowfullscreen></iframe>'
                );
            } else {
              $this
                .parent()
                .html(
                  '<iframe src="https://www.youtube-nocookie.com/embed/' +
                  $(this).data("video-embed") +
                  '?autoplay=1&rel=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>'
                );
            }
          });
        });
      },
    };

    // ---------- APP END
    $(document).ready(App.init);
  }
);

tarteaucitron.init({
  'hashtag': '#rgpd',
  'highPrivacy': true,
  'orientation': 'middle',
  'adblocker': false,
  'showAlertSmall': false,
  'cookieslist': true,
  'removeCredit': true,
  'useExternalCss': false,
  'showIcon': false,
});
tarteaucitron.job = tarteaucitron.job || [];

tarteaucitron.user.googletagmanagerId = 'GTM-TDRCDVP';
tarteaucitron.job.push('googletagmanager');
